/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable-next-line import/no-named-as-default */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { TextField, Button, Container, Typography, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ReCAPTCHA from "react-google-recaptcha";

function Message({ status }) {
  return (
    <>
      {status === 400 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px",
          }}
        >
          <CancelOutlinedIcon />
          Message failed to send
        </Box>
      )}
      {status === 200 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px",
          }}
        >
          <CheckCircleOutlineIcon />
          Message sent!
        </Box>
      )}
    </>
  );
}

Message.propTypes = {
  status: PropTypes.number.isRequired,
};

function Contact() {
  const token = process.env.REACT_APP_ACCESS_TOKEN;
  const table = process.env.REACT_APP_TABLE_NAME;
  const baseId = process.env.REACT_APP_BASE_ID;
  const recaptcha = useRef();

  const [formEntry, setFormEntry] = useState({
    loading: false,
    entryComplete: false,
    error: false,
  });
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    notes: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {
      const res = await fetch(
        `http://${process.env.REACT_APP_SITE_URL}/verify`,
        {
          method: "POST",
          body: JSON.stringify({ captchaValue }),
          headers: {
            "content-type": "application/json",
          },
        }
      );
      const data = await res.json();
      if (data.success) {
        try {
          setFormEntry({
            loading: true,
            entryComplete: false,
            error: false,
          });
          const response = await axios.post(
            `https://api.airtable.com/v0/${baseId}/${table}`,
            {
              fields: {
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email,
                phone: formData.phone,
                notes: formData.notes,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          setFormEntry({
            loading: false,
            entryComplete: true,
            error: false,
          });
        } catch (error) {
          console.error(error);
          setFormEntry({
            loading: false,
            entryComplete: false,
            error: true,
          });
        }
      } else {
        alert("reCAPTCHA validation failed!");
      }
    }
  };

  return (
    <Container
      id="contact"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          width: { sm: "60%", md: "60%" },
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          gutterBottom
          sx={{
            textAlign: { sm: "left", md: "center" },
          }}
        >
          Contact our team
        </Typography>
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "center", md: "center" },
            justifyContent: { sm: "center", md: "center" },
          }}
        >
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            sx={{ textAlign: "left" }}
          >
            Shoot us an email
          </Typography>

          <Container
            className="Contact__form--itemWrapper"
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              gap: { xs: 1, sm: 1 },
            }}
          >
            <AlternateEmailIcon />
            <Typography variant="body1" sx={{ color: "grey.400" }}>
              <a
                href="mailto:info@archerweb.co.uk?subject=Website%20Enquiry"
                style={{ color: "grey" }}
              >
                info@archerweb.co.uk
              </a>
            </Typography>
          </Container>
        </Box>
        <br />
        <Typography
          variant="h5"
          component="h1"
          gutterBottom
          sx={{ textAlign: "left" }}
        >
          Request a callback
        </Typography>
        <Typography variant="body1">
          Fill out an enquiry form with your requirements and we&apos;ll get in
          touch &#128512;
        </Typography>
      </Container>

      {!formEntry.error && !formEntry.entryComplete ? (
        <Box
          className="Contact__Wrapper"
          sx={{
            pt: { xs: 4, sm: 12 },
            pb: { xs: 8, sm: 16 },
            width: "100%",
            // color: "white",
            // bgcolor: "grey.900",
          }}
        >
          <Container
            id="contact-form"
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: { xs: 3, sm: 6 },
              width: { sm: "60%", md: "60%" },
            }}
          >
            <form onSubmit={handleSubmit} className="Contact__form">
              <Container
                className="Contact__form--itemWrapper"
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "left",
                  gap: { xs: 3, sm: 6 },
                }}
              >
                <Container
                  className="Contact__form--itemWrapper"
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "grey.900" }}
                    gutterBottom
                  >
                    First Name
                  </Typography>
                  <TextField
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Container>
                <Container
                  className="Contact__form--itemWrapper"
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "grey.900" }}
                    gutterBottom
                  >
                    Last Name
                  </Typography>
                  <TextField
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Container>
              </Container>
              <Container
                className="Contact__form--itemWrapper"
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: "grey.900" }}
                  gutterBottom
                >
                  Email
                </Typography>
                <TextField
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Container>
              <Container
                className="Contact__form--itemWrapper"
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: "grey.900" }}
                  gutterBottom
                >
                  Phone
                </Typography>
                <TextField
                  name="phone"
                  type="tel"
                  value={formData.phone}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Container>
              <Container
                className="Contact__form--itemWrapper"
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: "grey.900" }}
                  gutterBottom
                >
                  Message
                </Typography>
                <TextField
                  name="notes"
                  value={formData.notes}
                  onChange={handleChange}
                  placeholder="Provide a detailed explanation of your requirements"
                  variant="outlined"
                  fullWidth
                  multiline
                  required
                  rows={4}
                />
              </Container>
              <ReCAPTCHA
                id="reCaptcha"
                ref={recaptcha}
                sitekey={process.env.REACT_APP_SITE_KEY}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="Contact__button"
                disabled={formEntry.loading}
                sx={{
                  width: { sm: "100%", md: "100%" },
                }}
              >
                {formEntry.loading ? (
                  <CircularProgress
                    sx={{
                      width: { sm: "20px", md: "20px" },
                      color: "white",
                    }}
                  />
                ) : (
                  `Send Message`
                )}
              </Button>
            </form>
          </Container>
        </Box>
      ) : (
        <Message
          status={formEntry.error ? 400 : formEntry.entryComplete ? 200 : null}
        />
      )}
    </Container>
  );
}

export default Contact;

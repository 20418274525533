/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SiteLogo from "./SiteLogo";
import scrollToSection from "../hooks/scrollToSection";
import axios from "axios";
import { columnOne, columnThree } from "../consts/footer";
import extractNameFromEmail from "../hooks/useEmailExtract";

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: "text.secondary", mt: 1 }}>
      {"Copyright © "}
      <Link color="text.secondary" href="https://mui.com/">
        Archer Web Solutions
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

async function NewsletterSubmit(
  name,
  email,
  phone,
  notes,
  auth,
  handleSubscribed
) {
  const emailName = email && extractNameFromEmail(email);
  const token = auth?.token;
  const table = auth?.table;
  const baseId = auth?.baseId;

  try {
    if (auth && email) {
      const response = await axios.post(
        `https://api.airtable.com/v0/${baseId}/${table}`,
        {
          fields: {
            Name: name ? name : emailName,
            Email: email,
            Phone: phone,
            Notes: notes,
            Subscribed: true,
            DateJoined: new Date(),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      handleSubscribed();
      return response;
    }
  } catch (error) {
    console.error(error);
  }
}

export default function Footer() {
  const [subscribed, setSubscribed] = useState(false);
  const [alert, setAlert] = useState({ message: "", visible: false });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    notes: "",
  });
  const auth = {
    token: process.env.REACT_APP_ACCESS_TOKEN,
    table: process.env.REACT_APP_TABLE_NAME,
    baseId: process.env.REACT_APP_BASE_ID,
  };
  const handleChange = (e) => {
    setFormData({ ...formData, email: e.target.value });
  };
  const handleSubscribed = () => {
    setSubscribed(!subscribed);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ width: { xs: "100%", sm: "60%" } }}
          style={{
            display: "grid",
            justifyItems: "left",
          }}
        >
          <SiteLogo
            img="LogoNB2"
            boxWidth={{ mobile: "30%", tablet: "20%", desktop: "15%" }}
          />
          <Typography
            variant="body2"
            gutterBottom
            sx={{ fontWeight: 600, mt: 2 }}
          >
            Join the newsletter
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary", mb: 2 }}>
            Subscribe for updates, tips and offers. No spams ever!
          </Typography>
          <InputLabel htmlFor="email-newsletter">Email</InputLabel>

          {alert?.visible && (
            <Stack
              direction="row"
              spacing={1}
              useFlexGap
              style={{ marginBottom: "16px" }}
            >
              <Alert
                severity="warning"
                onClose={() => setAlert({ message: "", visible: false })}
              >
                {alert?.message}
              </Alert>
            </Stack>
          )}
          <Stack direction="row" spacing={1} useFlexGap>
            <TextField
              id="email-newsletter"
              hiddenLabel
              size="small"
              variant="outlined"
              fullWidth
              aria-label="Enter your email address"
              placeholder="Your email address"
              slotProps={{
                htmlInput: {
                  autoComplete: "off",
                  "aria-label": "Enter your email address",
                },
              }}
              onChange={handleChange}
              sx={{ width: "250px" }}
              required
            />
            <Button
              className="Footer__submitBtn"
              variant="contained"
              color="primary"
              size="small"
              sx={{ flexShrink: 0 }}
              disabled={subscribed}
              onClick={() => {
                if (!formData?.email) {
                  setAlert({
                    message: "Please enter an email address",
                    visible: true,
                  });
                  return;
                }
                if (!validateEmail(formData.email)) {
                  setAlert({
                    message: "Please enter a valid email address",
                    visible: true,
                  });
                  return;
                }
                NewsletterSubmit(
                  formData?.name,
                  formData?.email,
                  formData?.phone,
                  formData?.notes,
                  auth,
                  handleSubscribed
                );
              }}
            >
              {subscribed ? "Subscribed" : "Subscribe"}
            </Button>
          </Stack>
        </Box>
        {/* Column One */}
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
          style={{
            textAlign: "left",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            {columnOne?.heading}
          </Typography>
          {columnOne?.children?.map((item) => {
            return (
              <Link
                key={item.id}
                color="text.secondary"
                variant="body2"
                style={{ cursor: "pointer" }}
                onClick={() => scrollToSection(item.link)}
              >
                {item.label}
              </Link>
            );
          })}
        </Box>
        {/* Column Two */}
        {/* <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            {columnTwo?.heading}
          </Typography>
          {columnTwo?.children?.map((item) => {
            return (
              <Link
                key={item.id}
                color="text.secondary"
                variant="body2"
                href={item.link}
              >
                {item.label}
              </Link>
            );
          })}
        </Box> */}
        {/* Column Three */}
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
          style={{
            textAlign: "left",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            {columnThree?.heading}
          </Typography>
          {columnThree?.children?.map((item) => {
            return (
              <Link
                key={item.id}
                color="text.secondary"
                variant="body2"
                href={item.link}
              >
                {item.label}
              </Link>
            );
          })}
        </Box>
      </Box>
      {/* Bottom Footer inc Social media icons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
        style={{
          textAlign: "left",
        }}
      >
        <div>
          <Link color="text.secondary" variant="body2" href="/privacy">
            Privacy Policy
          </Link>
          <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" variant="body2" href="/terms">
            Terms of Service
          </Link>
          <Copyright />
        </div>
        {/* <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: "left", color: "text.secondary" }}
        >
          <IconButton
            color="inherit"
            size="small"
            href="https://github.com/mui"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://x.com/MaterialUI"
            aria-label="X"
            sx={{ alignSelf: "center" }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.linkedin.com/company/mui/"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack> */}
      </Box>
    </Container>
  );
}

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const tiers = [
  {
    title: "Basic",
    subheader: "Starting from £950",
    description: [
      "SPA (Single Page Application)",
      "Custom Domain + Email address",
      "Google Business profile",
      "Google Analytics dashboard",
      "Basic SEO",
    ],
    buttonVisible: false,
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
    buttonColor: "primary",
  },
  {
    title: "Advanced",
    subheader: "Starting from £1,550",
    description: [
      "Multi Page Site",
      // "API Calls",
      "Custom Database",
      // "Multi Admin users",
      "User Management",
      "Custom Domain + Email address",
      "Google Business profile + Analytics dashboard",
      "Basic SEO",
    ],
    buttonVisible: false,
    buttonText: "Start now",
    buttonVariant: "contained",
    buttonColor: "secondary",
  },
  {
    title: "Custom",
    subheader: "Most popular",
    description: [
      "Tailored to your specific requirements",
      "Extensive SEO Strategy",
      "Company wide IT Strategy",
      "AI & Automation Operations",
    ],
    buttonVisible: false,
    buttonText: "Contact us",
    buttonVariant: "outlined",
    buttonColor: "primary",
  },
];

export default function Pricing() {
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
        // color: "white",
        // bgcolor: "grey.900",
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: "text.primary" }}
        >
          Pricing
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          Discover our transparent and flexible pricing plans, designed to fit
          your needs and budget, ensuring you get the best value for your
          investment.
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{ alignItems: "center", justifyContent: "center", width: "100%" }}
      >
        {tiers.map((tier) => (
          <Grid
            size={{ xs: 12, sm: tier.title === "Enterprise" ? 12 : 6, md: 4 }}
            key={tier.title}
          >
            <Card
              sx={[
                {
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                },
                tier.title === "Advanced" &&
                  ((theme) => ({
                    border: "none",
                    background:
                      "radial-gradient(circle at 50% 0%, hsl(220deg 11.54% 41.47%), hsl(220, 30%, 50%))",
                    boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                    ...theme.applyStyles("dark", {
                      background:
                        "radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))",
                      boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                    }),
                  })),
              ]}
            >
              <CardContent>
                <Box
                  sx={[
                    {
                      mb: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2,
                    },
                    tier.title === "Advanced"
                      ? { color: "grey.100" }
                      : { color: "" },
                  ]}
                >
                  <Typography component="h2" variant="h4">
                    {tier.title}
                  </Typography>
                  {/* <Typography component="h3" variant="h6">
                    {tier.subtitle}
                  </Typography> */}
                  {tier.title === "Advanced" ? (
                    <Chip label={tier.subheader} />
                  ) : tier.title === "Basic" ? (
                    <Chip label={tier.subheader} />
                  ) : (
                    <Chip icon={<AutoAwesomeIcon />} label={tier.subheader} />
                  )}
                </Box>
                {/* Hide price row */}
                {/* <Box
                  sx={[
                    {
                      display: 'flex',
                      alignItems: 'baseline',
                    },
                    tier.title === 'Advanced'
                      ? { color: 'grey.50' }
                      : { color: null },
                  ]}
                >
                  <Typography component="h3" variant="h2">
                    ${tier.price}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp; per month
                  </Typography>
                </Box> */}
                <Divider sx={{ my: 2, opacity: 0.8, borderColor: "divider" }} />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={[
                        {
                          width: 20,
                        },
                        tier.title === "Advanced"
                          ? { color: "primary.light" }
                          : { color: "primary.main" },
                      ]}
                    />
                    <Typography
                      variant="subtitle2"
                      component={"span"}
                      sx={[
                        tier.title === "Advanced"
                          ? { color: "grey.50" }
                          : { color: null },
                      ]}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              {tier?.buttonVisible && (
                <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant}
                    color={tier.buttonColor}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

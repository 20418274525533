export default function extractPreviousWorkData(data) {
  let items = [];
  data?.map((item) => {
    const d = item.fields;
    return (
      d.Production &&
      items.push({
        title: d.Title,
        description: d.Description,
        img: d.Image[0].url,
        tag: d.Tag,
        link: d.WebsiteLink,
      })
    );
  });
  items.sort((a, b) => a.title.localeCompare(b.title));
  return items;
}

/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import * as React from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import axios from "axios";
import extractPreviousWorkData from "../hooks/useExtractWorkData";
import CircularProgress from "@mui/material/CircularProgress";

const auth = {
  token: process.env.REACT_APP_ACCESS_TOKEN_READ,
  table: process.env.REACT_APP_WORK_TABLE_NAME,
  baseId: process.env.REACT_APP_BASE_ID,
};

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: 0,
  height: "100%",
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  "&:focus-visible": {
    outline: "3px solid",
    outlineColor: "hsla(210, 98%, 48%, 0.5)",
    outlineOffset: "2px",
  },
}));

const StyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  gap: 4,
  padding: 16,
  flexGrow: 1,
  "&:last-child": {
    paddingBottom: 16,
  },
});

const StyledTypography = styled(Typography)({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
});

async function GetPreviousWork(auth) {
  const token = auth?.token;
  const table = auth?.table;
  const baseId = auth?.baseId;

  try {
    if (auth) {
      const response = await axios.get(
        `https://api.airtable.com/v0/${baseId}/${table}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return extractPreviousWorkData(response.data.records);
    }
  } catch (error) {
    console.error(error);
  }
}

function Author({ authors }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
        }}
      >
        <AvatarGroup max={3}>
          {authors.map((author, index) => (
            <Avatar
              key={index}
              alt={author.name}
              src={author.avatar}
              sx={{ width: 24, height: 24 }}
            />
          ))}
        </AvatarGroup>
        <Typography variant="caption">
          {authors.map((author) => author.name).join(", ")}
        </Typography>
      </Box>
      <Typography variant="caption">July 14, 2021</Typography>
    </Box>
  );
}

Author.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export function Search() {
  return (
    <FormControl sx={{ width: { xs: "100%", md: "25ch" } }} variant="outlined">
      <OutlinedInput
        size="small"
        id="search"
        placeholder="Search…"
        sx={{ flexGrow: 1 }}
        startAdornment={
          <InputAdornment position="start" sx={{ color: "text.primary" }}>
            <SearchRoundedIcon fontSize="small" />
          </InputAdornment>
        }
        inputProps={{
          "aria-label": "search",
        }}
      />
    </FormControl>
  );
}

export default function MainContent() {
  const [focusedCardIndex, setFocusedCardIndex] = React.useState(null);
  const [cardData, setCardData] = React.useState([]);

  const handleFocus = (index) => {
    setFocusedCardIndex(index);
  };

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  const handleClick = () => {
    console.info("You clicked the filter chip.");
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await GetPreviousWork(auth);
        setCardData(result);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <div>
        <Typography variant="h1" id="work" gutterBottom>
          Our Work
        </Typography>
        <Typography>
          Explore our portfolio showcasing innovative website designs, each
          tailored to meet the unique needs of our clients.
        </Typography>
      </div>

      <Grid container spacing={2} columns={12}>
        {cardData?.length > 0 ? (
          cardData?.map((card, i) => {
            return (
              <Grid key={i} size={{ xs: 12, md: 6 }}>
                <StyledCard
                  variant="outlined"
                  onFocus={() => handleFocus(0)}
                  onBlur={handleBlur}
                  tabIndex={0}
                  className={focusedCardIndex === 0 ? "Mui-focused" : ""}
                >
                  <CardMedia
                    component="img"
                    alt="Client Company Website Mockup"
                    image={card.img}
                    aspect-ratio="16 / 9"
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: "divider",
                    }}
                  />
                  <StyledCardContent>
                    <Typography gutterBottom variant="caption" component="div">
                      {card?.tag}
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                      {card.title}
                    </Typography>
                    <StyledTypography
                      variant="body2"
                      color="text.secondary"
                      gutterBottom
                    >
                      {card.description}
                    </StyledTypography>
                  </StyledCardContent>
                  {card?.authors && <Author authors={card?.authors} />}
                </StyledCard>
              </Grid>
            );
          })
        ) : (
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <CircularProgress />
          </Box>
        )}
      </Grid>
    </Box>
  );
}
